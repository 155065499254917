import { fork } from "redux-saga/effects";
import user from "./user";
import node from "./node";
import transactions from "./transactions";
import graph from "./graph";

export default function* root() {
  yield fork(user);
  yield fork(node);
  yield fork(transactions);
  yield fork(graph);
}
