import {
  LOGOUT_USER,
  SET_MOBILE,
  SET_NAME,
  SET_COUNTRY_OF_RESIDENCE,
  SET_WORK,
  UPDATE_USER,
  SEND_OTP,
  SET_COUNTRY_CODE,
  SET_AUTH_LS,
  SET_TOA,
  VERIFY_OTP,
  GET_TRANSACTIONS,
  GET_USER_DATA,
  SET_TOKEN,
  REFRESH_TOKEN,
  SET_LOADING,
  SET_ACCOUNTING_COUNTRY,
  GET_ASSETS_REMINDERS,
  GET_LIABILITIES_REMINDERS,
  SHARE_REMINDERS
} from './ActionTypes';
import lodashFind from 'lodash/find';
const countries = require('../country.json');

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
export const setMobile = (mobile) => {
  return {
    type: SET_MOBILE,
    mobile,
  };
};
export const setAccountingCountry = (accountingCountry) => {
  return {
    type: SET_ACCOUNTING_COUNTRY,
    accountingCountry,
  };
};
export const setName = (name) => {
  return {
    type: SET_NAME,
    name,
  };
};

export const setCountryOfResidence = (countryOfResidence) => {
  return {
    type: SET_COUNTRY_OF_RESIDENCE,
    countryOfResidence,
  };
};

export const setWork = (work) => {
  return {
    type: SET_WORK,
    work,
  };
};

export const sendOTP = (data) => {
  return {
    type: SEND_OTP.REQUEST,
    data,
  };
};
export const verifyOTP = (data) => {
  return {
    type: VERIFY_OTP.REQUEST,
    data,
  };
};
export const setCountryCode = (code) => {
  return {
    type: SET_COUNTRY_CODE,
    code,
  };
};
export const setAuthLS = (code) => {
  return {
    type: SET_AUTH_LS.REQUEST,
    code,
  };
};
export const setToa = (val) => {
  return {
    type: SET_TOA,
    val,
  };
};

export const getTransactionRequest = (val) => {
  return {
    type: GET_TRANSACTIONS.REQUEST,
    val,
  };
};
export function getUserDataRequest(responseCallback) {
  return {
    responseCallback,
    type: GET_USER_DATA.REQUEST,
  };
}
export function getUserDataSuccess(data) {
  return {
    data,
    type: GET_USER_DATA.SUCCESS,
  };
}
export function setToken(token) {
  return {
    token,
    type: SET_TOKEN,
  };
}
export function setLoading(loading) {
  const rett = '3';
  return {
    rett,
    type: SET_LOADING,
  };
}
export function updateUserRequest(data, responseCallback) {
  const payload = {};
  try {
    const user = { profile: {} };
    if (data.name) {
      user.profile.name = data.name;
    }
    if (data.loading) {
      user.loading = false;
    }
    if (data.picture) {
      user.profile.picture = data.picture;
    }
    if (data.work) {
      user.profile.work = data.work;
    }
    if (data.city) {
      user.profile.location = data.city;
    }
    if (data.mobile) {
      user.profile.number = data.mobile;
    }
    if (data.accountingCountry) {
      user.accountingCountry = data.accountingCountry;
    }
    if (data.premium) {
      user.profile.premium = data.premium;
    }
    else{
      user.profile.premium = false;
    }
    
    if (data.picture) user.profile.picture = data.picture;
    if (data.toa) user.profile.toa = data.toa;
    if (data.countryISO) {
      const country = lodashFind(countries, { alpha3: data.countryISO });
      if (country) {
        user.country = country.name;
        user.locale = country.default_locale;
        user.currency = country.currencies[0];
        user.countryCode = country.countryCallingCodes[0];
        user.countryISO = country.alpha3;
      }
    }

    if (data.countryAccount) {
      const country = lodashFind(countries, { alpha3: data.countryAccount });
      if (country) {
        user.accountingCountry = country.name;
        user.accountingCountryLocale = country.default_locale;
        user.accountingCountryCurrency = country.currencies[0];
        user.accountingCountryCode = country.countryCallingCodes[0];
        user.accountingCountryISO = country.alpha3;
      }
    }
    payload.user = user;

    return {
      type: UPDATE_USER.REQUEST,
      payload,
      responseCallback,
    };
  } catch (err) {
    console.log('\n error in updateUserRequest:', err.message || err);
    return {
      type: UPDATE_USER.REQUEST,
      payload,
      responseCallback,
    };
  }
}
export function updateUserSuccess(data) {
  return {
    data,
    type: UPDATE_USER.SUCCESS,
  };
}
export function refreshTokenSuccess(data) {
  return {
    data,
    type: REFRESH_TOKEN.SUCCESS,
  };
}

export const getLiabilitiesRemindersRequest = (val) => {
  return {
    type: GET_LIABILITIES_REMINDERS.REQUEST,
    val,
  };
};
export const getAssetsRemindersRequest = (val) => {
  return {
    type: GET_ASSETS_REMINDERS.REQUEST,
    val,
  };
};