/* eslint-disable import/no-anonymous-default-export */
// @flow
import Immutable from 'seamless-immutable';
import _ from 'lodash';
import {
  GET_TRANSACTIONS,
  GET_TRANSACTION_DETAIL,
  DELETE_TRANSACTION,
  LOGOUT_USER,
  GET_STATEMENTS,
  GET_LINKED_TRANSACTIONS,
  SET_LINKED_TRANSACTIONS,
  DELETE_LINKED_COLLECTION,
  DELETE_LINKED_TRANSACTION,
  RENAME_LINK,
  GET_BOOKMARKED,
  SET_BOOKMARKED,
  DELETE_BOOKMARKED,
  GET_ASSETS_REMINDERS,
  GET_LIABILITIES_REMINDERS,
  RESET_TRANSACTION
} from '../actions/ActionTypes';

const initialState = Immutable({
  transactions: [],
  transactionDetail:[],
  statements: [],
  linkedTransactions: [],
  bookmarked: [],
  assetsReminders: [],
  liabilitiesReminders: [],
  // chainName: 'India',
});

export default (state = initialState, action) => {

  switch (action.type) {
    case RESET_TRANSACTION:{
      return Immutable.merge(state, {
        transactionDetail: [],
      }); 
    }

    case GET_TRANSACTIONS.SUCCESS: {
      return Immutable.merge(state, {
        transactions: action.transactions,
      });
    }

    case GET_TRANSACTION_DETAIL.SUCCESS: {
      return Immutable.merge(state, {
        transactionDetail: action.ids,
      });
    }

    case GET_LINKED_TRANSACTIONS.SUCCESS: {
      return Immutable.merge(state, {
        linkedTransactions: action.linkedTransactions,
      });
    }

    case GET_STATEMENTS.SUCCESS: {
      return Immutable.merge(state, {
        statements: action,
      });
    }
    case DELETE_TRANSACTION.SUCCESS: {
      const temTransactions = _.cloneDeep(state.transactions);

      action.ids.forEach((id) => {
        const deletedIndex = _.findIndex(temTransactions, (item) => {
          const test = item[0]._id === id;
          return test;
        });
        temTransactions.splice(deletedIndex, 1);
      });

      return Immutable.merge(state, {
        transactions: temTransactions,
      });
    }
    case SET_LINKED_TRANSACTIONS.SUCCESS: {
      const indexToUpdate = state.linkedTransactions.findIndex(
        (transaction) => transaction._id === action.linkedTransactions._id,
      );
      if (indexToUpdate === -1) {
        return Immutable.merge(state, {
          linkedTransactions: state.linkedTransactions.concat(
            action.linkedTransactions,
          ),
        });
      } else {
        return state.set(
          'linkedTransactions',
          state.linkedTransactions.map((transaction, index) => {
            if (index === indexToUpdate) {
              return {
                ...transaction,
                transactions: action.linkedTransactions.transactions,
              };
            }
            return transaction;
          }),
        );
      }
    }
    case DELETE_LINKED_COLLECTION.SUCCESS: {
      return state.set(
        'linkedTransactions',
        state.linkedTransactions.filter(
          (transaction) => !action.payload.linkIds.includes(transaction._id),
        ),
      );
    }
    case DELETE_LINKED_TRANSACTION.SUCCESS: {
      const { linkId, transactionIds } = action.payload;
      return state.set(
        'linkedTransactions',
        state.linkedTransactions.map((transaction) => {
          if (transaction._id === linkId) {
            let newData = {
              ...transaction,
              transactions: transaction.transactions.filter(
                (t) => !transactionIds.includes(t),
              ),
            };
            return newData;
          }
          return transaction;
        }),
      );
    }
    case RENAME_LINK.SUCCESS: {
      //TODO update this after api response
      const { linkId, newName } = action.payload;
      return state.set(
        'linkedTransactions',
        state.linkedTransactions.map((transaction) => {
          if (transaction._id === linkId) {
            let newData = { ...transaction, name: newName };
            return newData;
          }
          return transaction;
        }),
      );
    }
    case GET_BOOKMARKED.SUCCESS: {
      return Immutable.merge(state, {
        bookmarked: action.bookmarkedTransactions,
      });
    }
    case SET_BOOKMARKED.SUCCESS: {
      const indexToUpdate = state.bookmarked.findIndex(
        (bookmark) =>
          bookmark._id === action?.bookmarkedTransactions?.transactionId,
      );
      if (indexToUpdate === -1) {
        const recordWithId = state.transactions.find(
          (record) =>
            record[0]._id == action?.bookmarkedTransactions?.transactionId,
        );
        if (recordWithId) {
          return Immutable.merge(state, {
            bookmarked: state.bookmarked.concat(recordWithId),
          });
        }
      }
      return state;
    }
    case DELETE_BOOKMARKED.SUCCESS: {
      const updatedBookmarked = state.bookmarked.filter(
        (bookmark) => bookmark._id !== action?.payload?.transactionId,
      );
      return Immutable.merge(state, {
        bookmarked: updatedBookmarked,
      });
    }
    case GET_ASSETS_REMINDERS.SUCCESS: {
      return Immutable.merge(state, {
        assetsReminders: action.data,
      });
    }
    case GET_LIABILITIES_REMINDERS.SUCCESS: {
      return Immutable.merge(state, {
        liabilitiesReminders: action.data,
      });
    }

    case LOGOUT_USER: {
      return Immutable.merge({ ...state, ...initialState });
    }
    default:
      return state;
  }
};
