/* eslint-disable import/no-anonymous-default-export */
import GoogleImage from '../assets/images/google.png';
import MainLogoImage from '../assets/images/profitize-logo.png';
import pencilImage from '../assets/images/pencil.png';
import BuyIcon from '../assets/images/logo/buy.png';
import SellIcon from '../assets/images/logo/sell.png';
import FinanceIcon from '../assets/images/logo/finance.png';
import InvestIcon from '../assets/images/logo/invest.png';
import TaxIcon from '../assets/images/logo/tax.png';
import MoreIcon from '../assets/images/logo/more.png';
import SuccessIcon from '../assets/images/logo/success.png';
import PhoneOutlined from '../assets/images/PhoneOutlined.png';
import Briefcase from '../assets/images/Briefcase.png';
import UserOutlined from '../assets/images/UserOutlined.png';
import HomeImage from '../assets/images/Home.png';
import HomeOutlinedImage from '../assets/images/HomeOutlined.png';
import TransactionImage from '../assets/images/Transaction.png';
import TransactionOutlinedImage from '../assets/images/TransactionOutlined.png';
import PersonImage from '../assets/images/Person.png';
import PersonOutlinedImage from '../assets/images/PersonOutlined.png';
import askAccountant from '../assets/images/askAccountant.png';
import IN from '../assets/images/in.png';
import BDG from '../assets/images/bdg.png';
import USA from '../assets/images/usa.png';
import UK from '../assets/images/uk.png';
import Loader from '../assets/images/loader.gif';
import logoMain from '../assets/images/profitize-logo.png'
import logoMainLogin from '../assets/images/profitize-logo-main.png'
import Globe from '../assets/images/globe.png';
import Location from '../assets/images/location.png';
import Support from '../assets/images/online-support.png';

export default {
  BuyIcon,
  SellIcon,
  FinanceIcon,
  InvestIcon,
  TaxIcon,
  MoreIcon,
  SuccessIcon,
  PhoneOutlined,
  Briefcase,
  UserOutlined,
  HomeImage,
  HomeOutlinedImage,
  TransactionImage,
  TransactionOutlinedImage,
  PersonImage,
  PersonOutlinedImage,
  GoogleImage,
  MainLogoImage,
  pencilImage,
  IN,
  BDG,
  USA,
  UK,
  askAccountant,
  Loader,
  logoMain,
  logoMainLogin,
  Globe,
  Location,
  Support,
};
