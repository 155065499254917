import {
  GET_TRANSACTIONS,
  DELETE_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  GET_LINKED_TRANSACTIONS,
  GET_STATEMENTS,
  SET_OPENING_BAL,
  SET_CATEGORY,
  SET_LINKED_TRANSACTIONS,
  DELETE_LINKED_TRANSACTION,
  DELETE_LINKED_COLLECTION,
  RENAME_LINK,
  GET_BOOKMARKED,
  SET_BOOKMARKED,
  DELETE_BOOKMARKED,
  GET_LIABILITIES_REMINDERS,
  GET_ASSETS_REMINDERS,
  SHARE_REMINDERS,
  RESET_TRANSACTION
} from './ActionTypes';
import DataHandler from '../services/DataHandler';
import toLower from 'lodash/toLower';
import _ from 'lodash';

export const getTransactionsRequest = () => {
  return {
    type: GET_TRANSACTIONS.REQUEST,
  };
};

export const resetTransactionDetails = () => {
  return {
    type: RESET_TRANSACTION,
  };
};

export const getLinkedTransactionsRequest = (responseCallback) => {
  return {
    responseCallback,
    type: GET_LINKED_TRANSACTIONS.REQUEST,
  };
};

export const getLinkedTransactionsSuccess = (linkedTransactions) => {
  return {
    type: GET_LINKED_TRANSACTIONS.SUCCESS,
    linkedTransactions,
  };
};

export const setLinkedTransactionRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: SET_LINKED_TRANSACTIONS.REQUEST,
  };
};

export const setLinkedTransactionSuccess = (linkedTransactions) => {
  return {
    type: SET_LINKED_TRANSACTIONS.SUCCESS,
    linkedTransactions,
  };
};

export const setCategoryRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: SET_CATEGORY.REQUEST,
  };
};

export const getTransactionsSuccess = (data) => {
  const modTransactions = [];
  const transactions = [];
  let statements = [];
  try {
    data.forEach((item) => {
      let amount = 0;
      let name = 'NA';
      const { _id, createdAt, type, glEntry, intent } = item;
      if (item.additionalData) {
        if (item.additionalData[0].array) {
          let assigned = false;
          item.additionalData[0].array.forEach((arrayItem) => {
            const key = Object.keys(arrayItem)[0];

            if (
              (key.split('Name').join('') !== key ||
                key.split('name').join('') !== key) &&
              !assigned
            ) {
              name = item.additionalData[0][key];
              assigned = true;
            }
          });
        } else {
          Object.keys(item.additionalData).forEach((key) => {
            if (
              key.split('Name').join('') !== key ||
              key.split('name').join('') !== key
            )
              name = item.additionalData[0][key];
          });
        }
      }
      glEntry.forEach((glElement) => {
        glElement.options.forEach((element) => {
          if (
            element.name === 'creditLabel' ||
            element.name === 'transactionAmount'
          ) {
          }
          if (element.value === 'BLANK') {
            element.value = '--';
          }
          if (element.name === 'transactionAmount') {
            amount += parseFloat(element.value.split(',').join(''));
          }
        });
      });

      const modItem = [
        {
          amount: amount / 2,
          name,
          type: toLower(type),
          date: createdAt,
          _id,
          intent: intent || null,
        },
      ].concat(item);
      transactions.push(modItem);
    });
  } catch (error) {
    console.error(error);
  }

  return {
    type: GET_TRANSACTIONS.SUCCESS,
    transactions,
  };
};

export const deleteTransactionsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: DELETE_TRANSACTION.REQUEST,
  };
};
export const getStatementsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: GET_STATEMENTS.REQUEST,
  };
};
export const setOpeningBalRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: SET_OPENING_BAL.REQUEST,
  };
};

export const deleteTransactionsSuccess = (ids) => {
  return {
    ids,
    type: DELETE_TRANSACTION.SUCCESS,
  };
};
export const setOpeningBalRequestSuccess = (ids) => {
  return {
    ids,
    type: SET_OPENING_BAL.SUCCESS,
  };
};
export const getStatementsSuccess = (data) => {
  const statements = [];
  statements.push(data);
  return {
    statements,
    type: GET_STATEMENTS.SUCCESS,
  };
};

export const getTransactionDetailsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: GET_TRANSACTION_DETAIL.REQUEST,
  };
};
export const getTransactionDetailsSuccess = (ids) => {
  return {
    ids,
    type: GET_TRANSACTION_DETAIL.SUCCESS,
  };
};

export const deleteLinkedTransactionsRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: DELETE_LINKED_TRANSACTION.REQUEST,
  };
};
export const deleteLinkedTransactionsSuccess = (payload) => {
  return {
    payload,
    type: DELETE_LINKED_TRANSACTION.SUCCESS,
  };
};

export const deleteLinkedCollectionRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: DELETE_LINKED_COLLECTION.REQUEST,
  };
};
export const deleteLinkedCollectionSuccess = (payload) => {
  return {
    payload,
    type: DELETE_LINKED_COLLECTION.SUCCESS,
  };
};

export const renameLinkRequest = (payload, responseCallback) => {
  return {
    payload,
    responseCallback,
    type: RENAME_LINK.REQUEST,
  };
};
export const renameLinkSuccess = (payload) => {
  return {
    payload,
    type: RENAME_LINK.SUCCESS,
  };
};
export const getBookmarkRequest = () => {
  return {
    type: GET_BOOKMARKED.REQUEST,
  };
};

export const getBookmarkSuccess = (bookmarkedTransactions) => {
  return {
    type: GET_BOOKMARKED.SUCCESS,
    bookmarkedTransactions,
  };
};

export const setBookmarkRequest = (payload) => {
  return {
    payload,
    type: SET_BOOKMARKED.REQUEST,
  };
};

export const setBookmarkSuccess = (bookmarkedTransactions) => {
  return {
    type: SET_BOOKMARKED.SUCCESS,
    bookmarkedTransactions,
  };
};

export const deleteBookmarkRequest = (payload) => {
  return {
    payload,
    type: DELETE_BOOKMARKED.REQUEST,
  };
};
export const deleteBookmarkSuccess = (payload) => {
  return {
    payload,
    type: DELETE_BOOKMARKED.SUCCESS,
  };
};


export const getLiabilitiesRemindersRequest = () => {
  return {
    type: GET_LIABILITIES_REMINDERS.REQUEST,
  };
};

export const getAssetRemindersRequest = () => {
  return {
    type: GET_ASSETS_REMINDERS.REQUEST,
  };
};


export const shareRemindersRequest = () => {
  return {
    type: SHARE_REMINDERS.REQUEST,
  };
};


export const getLiabilitiesRemindersSuccess = (data) => {
  return {
    type: GET_LIABILITIES_REMINDERS.SUCCESS,
    data,
  };
};

export const getAssetsRemindersSuccess = (data) => {
  console.log('DATA++++++++++',data)
  return {
    type: GET_ASSETS_REMINDERS.SUCCESS,
    data,
  };
};