import {
  SET_GRAPH_FILTER,
  GET_TIME_SERIES_AMOUNT,
  GET_GRAPH_FILTER,
  GET_NUMBER_OF_TRANSACTION,
  GET_CREDIT_REQUET_BY_CUSTOMER_NAME,
  SET_CREDIT_REQUET_BY_CUSTOMER_NAME,
  SET_DEBIT_REQUET_BY_CUSTOMER_NAME,
  GET_DEBIT_REQUET_BY_CUSTOMER_NAME,
  SET_TRANSACTION_BY_CUSTOMER_NAME,
  GET_TRANSACTION_BY_CUSTOMER_NAME,
  GET_TOTAL_TRANSACTIONS_BY_TYPE,
  SET_TOTAL_TRANSACTIONS_BY_TYPE,
  GET_CREDIT_TRANSACTIONS_BY_TYPE,
  SET_CREDIT_TRANSACTIONS_BY_TYPE,
  GET_DEBIT_TRANSACTIONS_BY_TYPE,
  SET_DEBIT_TRANSACTIONS_BY_TYPE,
  GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT,
  SET_TIME_SERIES_AMOUNT,
  SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT,
  SET_NUMBER_OF_TRANSACTION,
  SET_CASH_FLOW,
  GET_CASH_FLOW,
  SET_TRADE_RECEIVABLES,
  GET_TRADE_RECEIVABLES,
  GET_TRADE_PAYABLES,
  SET_TRADE_PAYABLES,
  SET_REVENUE,
  GET_REVENUE,
  SET_MATERIALS,
  SET_CASHOUT,
  SET_CASHIN,
  SET_PURCHASES,
  SET_INVENTORY,
  GET_MATERIALS,
  GET_CASHOUT,
  GET_CASHIN,
  GET_PURCHASES,
  GET_INVENTORY,
  GET_CASHBALANCE,
  SET_CASHBALANCE,
  GET_CASHBOX,
  SET_CASHBOX
} from './ActionTypes';

export const getGraphFilterRequest = () => {
  return {
    type: GET_GRAPH_FILTER.REQUEST,
  };
};

export const getCreditRequestAmountByCustomerName = (payload) => {
  return {
    type: GET_CREDIT_REQUET_BY_CUSTOMER_NAME.REQUEST,
    payload,
  };
};

export const getDebitRequestAmountByCustomerName = (payload) => {
  return {
    type: GET_DEBIT_REQUET_BY_CUSTOMER_NAME.REQUEST,
    payload,
  };
};

export const getTransactionsByCustomerName = (payload) => {
  return {
    type: GET_TRANSACTION_BY_CUSTOMER_NAME.REQUEST,
    payload,
  };
};

export const getTotalTransactionByType = (payload) => {
  return {
    type: GET_TOTAL_TRANSACTIONS_BY_TYPE.REQUEST,
    payload,
  };
};

export const getCreditTransactionByType = (payload) => {
  return {
    type: GET_CREDIT_TRANSACTIONS_BY_TYPE.REQUEST,
    payload,
  };
};

export const getDebitTransactionByType = (payload) => {
  return {
    type: GET_DEBIT_TRANSACTIONS_BY_TYPE.REQUEST,
    payload,
  };
};

export const getTotalTransactionByMonthCreditDebit = (payload) => {
  return {
    type: GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT.REQUEST,
    payload,
  };
};

export const getTimeSeriesAmount = (payload) => {
  return {
    type: GET_TIME_SERIES_AMOUNT.REQUEST,
    payload,
  };
};
export const getNumberOfTransactions = (payload) => {
  return {
    type: GET_NUMBER_OF_TRANSACTION.REQUEST,
    payload,
  };
};
export const getCashFlow = (payload) => {
  return {
    type: GET_CASH_FLOW.REQUEST,
    payload,
  };
};
export const getTradeReceivables = (payload) => {
  return {
    type: GET_TRADE_RECEIVABLES.REQUEST,
    payload,
  };
};
export const getTradePayables = (payload) => {
  return {
    type: GET_TRADE_PAYABLES.REQUEST,
    payload,
  };
};
export const getRevenue = (payload) => {
  return {
    type: GET_REVENUE.REQUEST,
    payload,
  };
};

export const getMaterials = (payload) => {
  return {
    type: GET_MATERIALS.REQUEST,
    payload,
  };
};

export const getCashout = (payload) => {
  return {
    type: GET_CASHOUT.REQUEST,
    payload,
  };
};
export const getCashin = (payload) => {
  return {
    type: GET_CASHIN.REQUEST,
    payload,
  };
};
export const getPurchases = (payload) => {
  return {
    type: GET_PURCHASES.REQUEST,
    payload,
  };
};
export const getInventory = (payload) => {
  return {
    type: GET_INVENTORY.REQUEST,
    payload,
  };
};

export const getCashBalance = (payload) => {
  return {
    type: GET_CASHBALANCE.REQUEST,
    payload,
  };
};

export const setGraphFilter = (graphFilter) => {
  return {
    type: SET_GRAPH_FILTER,
    graphFilter,
  };
};

export const setCreditRequestAmountByCustomerName = (
  creditRequestByCustomerName,
) => {
  return {
    type: SET_CREDIT_REQUET_BY_CUSTOMER_NAME,
    creditRequestByCustomerName,
  };
};

export const setDebitRequestAmountByCustomerName = (
  debitRequestByCustomerName,
) => {
  return {
    type: SET_DEBIT_REQUET_BY_CUSTOMER_NAME,
    debitRequestByCustomerName,
  };
};

export const setTransactionsByCustomerName = (transactionsByCustomerName) => {
  return {
    type: SET_TRANSACTION_BY_CUSTOMER_NAME,
    transactionsByCustomerName,
  };
};

export const setTotalTransactionType = (totalTransactionByType) => {
  return {
    type: SET_TOTAL_TRANSACTIONS_BY_TYPE,
    totalTransactionByType,
  };
};

export const setCreditTransactionByType = (creditTransactionByType) => {
  return {
    type: SET_CREDIT_TRANSACTIONS_BY_TYPE,
    creditTransactionByType,
  };
};

export const setDebitTransactionByType = (debitTransactionByType) => {
  return {
    type: SET_DEBIT_TRANSACTIONS_BY_TYPE,
    debitTransactionByType,
  };
};

export const setTotalTransactionByMonthCreditDebit = (
  totalTransactionByMonthCreditDebit,
) => {
  return {
    type: SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT,
    totalTransactionByMonthCreditDebit,
  };
};

export const setTimeSeriesAmount = (totalTimeSeriesData) => {
  return {
    type: SET_TIME_SERIES_AMOUNT,
    totalTimeSeriesData,
  };
};
export const setNoOfTransactions = (noofTransactions) => {
  return {
    type: SET_NUMBER_OF_TRANSACTION,
    noofTransactions,
  };
};
export const setCashFlow = (noofCashFlow) => {
  return {
    type: SET_CASH_FLOW,
    noofCashFlow,
  };
};

export const setTradeReceivables = (noofTradeReceivables) => {
  return {
    type: SET_TRADE_RECEIVABLES,
    noofTradeReceivables,
  };
};

export const setTradePayables = (noofTradePayables) => {
  return {
    type: SET_TRADE_PAYABLES,
    noofTradePayables,
  };
};

export const setRevenue = (noofRevenue) => {
  return {
    type: SET_REVENUE,
    noofRevenue,
  };
};

export const setMaterials = (noofMaterials) => {
  return {
    type: SET_MATERIALS,
    noofMaterials,
  };
};

export const setCashout = (noofCashout) => {
  return {
    type: SET_CASHOUT,
    noofCashout,
  };
};

export const setCashin = (noofCashin) => {
  return {
    type: SET_CASHIN,
    noofCashin,
  };
};

export const setPurchases = (noofPurchases) => {
  return {
    type: SET_PURCHASES,
    noofPurchases,
  };
};


export const setInventory = (noofInventory) => {
  return {
    type: SET_INVENTORY,
    noofInventory,
  };
};

export const setCashBalance = (noofCashBalance) => {
  return {
    type: SET_CASHBALANCE,
    noofCashBalance,
  };
};

export const getCashBox = (payload) => {
  return {
    type: GET_CASHBOX.REQUEST,
    payload,
  };
};

export const setCashBox = (cashBoxData) => {
  return {
    type: SET_CASHBOX,
    cashBoxData,
  };
};

