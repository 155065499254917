/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
// @flow
// eslint-disable-next-line max-classes-per-file
import {
  API_LOG,
  ERROR_SOMETHING_WENT_WRONG,
  BASE_URL,
  REQUEST_TYPE,
  ERROR_SESSION_EXPIRED,
  ABORT_REQUEST_MESSAGE,
  API_TIMEOUT,
} from '../config/WebService';
import DataHandler from '../services/DataHandler';
import _ from 'lodash';

import { logoutUser } from '../actions/UserActions';
import { calculateEtaRequest } from '../actions/GeneralActions';
import { FAILED_TO_FETCH, SOMETHING_WRONG } from '../constants';
import Util from './Util';

let timer;
const getOptions = (reqType, data, headers) => {
  // eslint-disable-next-line no-undef
  const controller = new AbortController();
  setTimeout(() => {
    controller.abort();
  }, API_TIMEOUT);
  const options = { method: reqType };
  options.credentials = 'include';
  if (reqType !== REQUEST_TYPE.GET) options.body = JSON.stringify(data);
  options.headers = {
    ...headers,
    ...{
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    },
  };
  options.signal = controller.signal;
  return options;
};

const onForbidden = async () => {
  const newToken = await Util.refreshAccessToken();

  if (newToken) {
    return newToken;
  }

  return false;
};
class RequestAbort extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = 'ForcedAbortRequest'; // (2)
  }
}

class ApiSauce {
  async post(url, data, headers, baseUrl) {
    if (API_LOG) {
      console.log('url', url);
      console.log('data', data);
      console.log('check new headers', headers);
    }

    try {
      const response = await fetch(
        `${baseUrl || BASE_URL}${url}`,
        getOptions(REQUEST_TYPE.POST, data, headers),
      );
      // return this.manipulateResponse(response);
      if (response.status === 401) {
        try {
          // Below function will store new CSRF token
          const newToken = await onForbidden();

          if (newToken) {
            headers.Authorization = `Bearer ${newToken}`;
          } else {
            DataHandler.getStore().dispatch(logoutUser());
            return false;
          }
        } catch (err) {
          // Util.topAlertError(SOMETHING_WRONG);
          DataHandler.getStore().dispatch(logoutUser());
        }

        const responseNew = await fetch(
          `${baseUrl || BASE_URL}${url}`,
          getOptions(REQUEST_TYPE.POST, data, headers),
        );

        return this.manipulateResponse(responseNew, url, data);
      } else {
        return this.manipulateResponse(response, url, data);
      }
    } catch (err) {
      return this.catchResponseError(err);
    }
  }

  // eslint-disable-next-line class-methods-use-this

  async get(url, data, headers, baseUrl) {
    if (API_LOG) {
      console.log('url', url);
      console.log('data', data);
      console.log('headers', headers);
    }
    try {
      const response = await fetch(
        `${baseUrl || BASE_URL}${url}`,
        getOptions(REQUEST_TYPE.GET, data, headers),
      );
      if (response.status === 401) {
        try {
          // Below function will store new CSRF token
          const newToken = await onForbidden();

          if (newToken) {
            headers.Authorization = `Bearer ${newToken}`;
          } else {
            DataHandler.getStore().dispatch(logoutUser());
            return false;
          }
        } catch (err) {
          // Util.topAlertError(SOMETHING_WRONG);
          DataHandler.getStore().dispatch(logoutUser());
        }

        const responseNew = await fetch(
          `${baseUrl || BASE_URL}${url}`,
          getOptions(REQUEST_TYPE.GET, data, headers),
        );

        return this.manipulateResponse(responseNew, url, data);
      } else {
        return this.manipulateResponse(response, url, data);
      }
    } catch (err) {
      return this.catchResponseError(err);
    }
  }

  async delete(url, data, headers, baseUrl) {
    if (API_LOG) {
      console.log('url', url);
      console.log('data', data);
      console.log('headers', headers);
    }

    try {
      const response = await fetch(
        `${baseUrl || BASE_URL}${url}`,
        getOptions(REQUEST_TYPE.DELETE, data, headers),
      );
      if (response.status === 401) {
        try {
          // Below function will store new CSRF token
          const newToken = await onForbidden();

          if (newToken) {
            headers.Authorization = `Bearer ${newToken}`;
          } else {
            DataHandler.getStore().dispatch(logoutUser());
            return false;
          }
        } catch (err) {
          // Util.topAlertError(SOMETHING_WRONG);
          DataHandler.getStore().dispatch(logoutUser());
        }

        const responseNew = await fetch(
          `${baseUrl || BASE_URL}${url}`,
          getOptions(REQUEST_TYPE.DELETE, data, headers),
        );

        return this.manipulateResponse(responseNew, url, data);
      } else {
        return this.manipulateResponse(response, url, data);
      }
    } catch (error) {
      return this.catchResponseError(error);
    }
  }

  async put(url, data, headers, baseUrl) {
    if (API_LOG) {
      console.log('base', baseUrl);
      console.log('BASE', BASE_URL);
      console.log('url', url);
      console.log('data', data);
      console.log('headers', headers);
    }
    try {
      const response = await fetch(
        `${baseUrl || BASE_URL}${url}`,
        getOptions(REQUEST_TYPE.PUT, data, headers),
      );

      if (response.status === 401) {
        try {
          // Below function will store new CSRF token
          const newToken = await onForbidden();

          if (newToken) {
            headers.Authorization = `Bearer ${newToken}`;
          } else {
            DataHandler.getStore().dispatch(logoutUser());
            return false;
          }
        } catch (err) {
          // Util.topAlertError(SOMETHING_WRONG);
          DataHandler.getStore().dispatch(logoutUser());
        }

        const responseNew = await fetch(
          `${baseUrl || BASE_URL}${url}`,
          getOptions(REQUEST_TYPE.PUT, data, headers),
        );

        return this.manipulateResponse(responseNew, url, data);
      } else {
        return this.manipulateResponse(response, url, data);
      }
    } catch (error) {
      return this.catchResponseError(error);
    }
  }
  async patch(url, data, headers, baseUrl) {
    if (API_LOG) {
      console.log('base', baseUrl);
      console.log('BASE', BASE_URL);
      console.log('url', url);
      console.log('data', data);
      console.log('headers', headers);
    }
    try {
      const response = await fetch(
        `${baseUrl || BASE_URL}${url}`,
        getOptions(REQUEST_TYPE.PATCH, data, headers),
      );

      if (response.status === 401) {
        try {
          // Below function will store new CSRF token
          const newToken = await onForbidden();

          if (newToken) {
            headers.Authorization = `Bearer ${newToken}`;
          } else {
            DataHandler.getStore().dispatch(logoutUser());
            return false;
          }
        } catch (err) {
          // Util.topAlertError(SOMETHING_WRONG);
          DataHandler.getStore().dispatch(logoutUser());
        }

        const responseNew = await fetch(
          `${baseUrl || BASE_URL}${url}`,
          getOptions(REQUEST_TYPE.PATCH, data, headers),
        );

        return this.manipulateResponse(responseNew, url, data);
      } else {
        return this.manipulateResponse(response, url, data);
      }
    } catch (error) {
      return this.catchResponseError(error);
    }
  }

  catchResponseError(err) {
    if (err.name === 'AbortError') {
      throw new RequestAbort(ABORT_REQUEST_MESSAGE);
    } else {
      console.error('Uh oh, an error!', err);
      return {
        status: false,
        message:
          err.message == 'Failed to fetch' ? FAILED_TO_FETCH : err.message,
      };
    }
  }
  // eslint-disable-next-line class-methods-use-this
  async manipulateResponse(response, url, data) {
    try {
      const responseJson = await response.json();

      // responseJson.isLoggedIn = false;

      // check if session out then redirect to login page
      if (!_.isUndefined(responseJson.isLoggedIn) && !responseJson.isLoggedIn) {
        DataHandler.getStore().dispatch('userLogoutRequest()');
      }

      return new Promise((resolve, reject) => {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            resolve(responseJson);
          } else {
            if (response.status === 403) {
              reject(ERROR_SESSION_EXPIRED);
            }
            reject(responseJson || ERROR_SOMETHING_WENT_WRONG);
          }
        } else {
          reject(responseJson || ERROR_SOMETHING_WENT_WRONG);
        }
      });
    } catch (err) {
      return err;
    }
  }
}

export default new ApiSauce();
