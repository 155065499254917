import { combineReducers } from "redux";
import user from "./user";
import node from "./node";
import transactions from "./transactions";
import graph from "./graph";

export default combineReducers({
  user,
  node,
  Intl,
  transactions,
  graph,
});
