import React from 'react';
import { Provider } from 'react-redux';
// import Util from "./services/Util";
import Routing from './router/index';
import configureStore from './store';
import DataHandler from './services/DataHandler';
import { persistStore } from 'redux-persist';
import './App.css';
const reducers = require('./reducers').default;

export default class App extends React.Component {
  persistor = {};
  state = {
    isLoading: true,
    isRehydrationComplete: false,
    store: configureStore(reducers, () => {
      this._loadingCompleted();
      this.setState({ isLoading: false });
    }),
  };

  _loadingCompleted = () => {
    try {
      DataHandler.setStore(this.state.store);
      // Util.getCsrfToken();
      //this.persistor = persistStore(this.state.store);
      this.persistor = persistStore(this.state.store, null, () => {
        // Do something when rehydration is complete
        console.log('Rehydration is complete!');
        this.setState({ isRehydrationComplete: true });
        this.loadScript();
      });
    } catch (err: any) {
      console.log('\n\n\n\n=== _loadingCompleted error:', err.message || err)
    }
  };

  loadScript = () => {
    try {
      if (!this.state.isRehydrationComplete) {
        return;
      }
      const userData = DataHandler.getStore().getState().user; // Replace with your actual state slice
      if (!userData || !userData.token) return
      const BASE_URL = "https://aspen2-web.dep.lifeel.in";

      // Create a script element
      const script = document.createElement('script');
      script.src = BASE_URL + "/packs/js/sdk.js";
      script.defer = true;
      script.async = true;

      // Append the script to the document body
      document.body.appendChild(script);

      const handleChatWidgetClick = (e: any) => {
        try {
          const userDetail = DataHandler.getStore().getState().user;
          if (!userDetail && !userDetail?.mobile || !userDetail?.countryCode) {
            return
          }
          let customAttribute = {
            name: userDetail?.name || '',
            phone: `${userDetail?.countryCode}${userDetail?.mobile}`,
            email: userDetail?.email
          }
          // Perform actions when chat widget is clicked
          //@ts-ignore
          window.$lifeel.setCustomAttributes(customAttribute);
        } catch (err: any) {
          console.error('\n error in handleChatWidgetClick:', err.message || err)

        }
      }
      const onChatBoxReady = () => {
        try {
          var chatWidget: any = document.querySelector(".woot-widget-bubble");
          chatWidget.addEventListener("click", handleChatWidgetClick);
        } catch (err: any) {
          console.error('\n error in onChatBoxReady:', err.message || err)
        }
      }
      //TODo: Not able to fire this event but from logout function conversation reset done
      const onChatMessage = (e: any) => {
        try {
          const convo_id = e.detail?.conversation_id;
          console.log('\n conversation id:', convo_id)
          if (e?.detail?.message_type === 0 && e?.detail?.content.trim()) {
            let message = e?.detail?.content.replace(/\s/g, '').toLowerCase();
            console.log('\n user input message...', message)
            //@ts-ignore
            window.$lifeel.setLabel(message)
          }
        } catch (err: any) {
          console.log('\n error in chat message:', err.message || err)
        }
      }

      // Execute the script once it's loaded
      script.onload = () => {
        try {
          //@ts-ignore
          window.lifeelSDK.run({
            websiteToken: 'ixZGnP3hSy9RDu1H3CJto3du', // Replace with your website token
            baseUrl: BASE_URL,
            position: {
              // Set the desired position of the chatbox
              top: '10px',
              right: '10px',
            },
          });
          // Add the event listener after the script is loaded
          window.addEventListener('lifeel:ready', onChatBoxReady);
          window.addEventListener('lifeel:on-message', onChatMessage);

          //Add event lister for get conversationId
          console.log("\n\n\n\n\n Event listener attached");
        } catch (e: any) {
          console.log('\n error on script load...', e.message || e)
        }

      };
    } catch (err: any) {
      console.log('\n error in load script:', err.message || err)
    }
  };

  render() {
    // if (this.state.isLoading) {
    //   return <div className="loading">Loading...</div>;
    // }

    return (
      <Provider store={this.state.store}>
        <React.StrictMode>
          <Routing />
          {/* <MuiPickersUtilsProvider utils={DayJSUtils}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/register" />
                      </Route>
                      <Route path="/home" component={Home} />
                      <Route path="/register" component={Register} />
                      <Route path="/add" component={AddTransaction} />
                      <Route path="/transaction/:id" component={Transaction} />
                      <Route component={() => <Redirect to="/home" />} />
                    </Switch>
                  </Suspense>
              </BrowserRouter>
            </ThemeProvider>
          </MuiPickersUtilsProvider> */}
        </React.StrictMode>
      </Provider>
    );
  }
}

// const Loading = () => {
//   return <div className="loading">Loading...</div>;
// };

// const App: React.FC = (): JSX.Element => {
//   const [someStore, setStore] = useState({});
//   const [loading, setLoading] = useState(true);

//   setStore(configureStore(reducers ,()=>{
//     setLoading(false)
//   }))
//   const dispatch = useDispatch();
//   if(!_.isEmpty(tkn)){
//     dispatch(setToken(tkn))
// }
// if(loading){
//   return <Loading/>
// }
//   return (
//     <Provider store={someStore}>
//     <React.StrictMode>
//     <MuiPickersUtilsProvider utils={DayJSUtils}>
//     <ThemeProvider theme={theme}>
//       <BrowserRouter>
//           <Suspense fallback={<Loading />}>
//             <Switch>
//               <Route exact path="/">
//                 <Redirect to="/register" />
//               </Route>
//               <Route path="/home" component={Home} />
//               <Route path="/register/:tkn" component={Register} />
//               <Route path="/register" component={Register} />
//               <Route path="/add" component={AddTransaction} />
//               <Route path="/transaction/:id" component={Transaction} />
//               <Route component={() => <Redirect to="/home" />} />
//             </Switch>
//           </Suspense>
//       </BrowserRouter>
//     </ThemeProvider>
//     </MuiPickersUtilsProvider>
//     </React.StrictMode>
//     </Provider>
//   );
// };

// export default App;
