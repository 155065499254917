// @flow
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const CANCEL = 'CANCEL';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  const res = {};
  [REQUEST, SUCCESS, FAILURE, CANCEL].forEach((type) => {
    res[type] = `${base}_${type}`;
  });
  return res;
}

export const NETWORK_INFO = 'NETWORK_INFO';
export const USER_UPLOAD_LOGO = createRequestTypes('USER_UPLOAD_LOGO');

export const SET_NEXT_NODE = 'SET_NEXT_NODE';
export const GET_NEXT_NODE = createRequestTypes('GET_NEXT_NODE');
export const GET_SHOW_MORE_NEXT_NODE = createRequestTypes(
  'GET_SHOW_MORE_NEXT_NODE',
);
export const SET_LOADING = 'SET_LOADING';
export const RESET_NODE = 'RESET_NODE';
export const PUSH_NODE_DATA = 'PUSH_NODE_DATA';
export const POP_NODE_DATA = createRequestTypes('POP_NODE_DATA');
export const SET_CHAIN = 'SET_CHAIN';
export const SET_MOBILE = 'SET_MOBILE';
export const SET_ACCOUNTING_COUNTRY = 'SET_ACCOUNTING_COUNTRY';
export const SET_NAME = 'SET_NAME';
export const SET_COUNTRY_OF_RESIDENCE = 'SET_COUNTRY_OF_RESIDENCE';

export const SET_CITY = 'SET_CITY';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_OTP_VERIFIED = 'SET_OTP_VERIFIED';
export const SET_TOA = 'SET_TOA';
export const SET_AUTH_LS = createRequestTypes('SET_AUTH_LS');
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_PICTURE = 'SET_PICTURE';
export const SET_GOOGLE_ACCESS_TOKEN = 'SET_GOOGLE_ACCESS_TOKEN';
export const SET_WORK = 'SET_WORK';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_AUTHORISED = 'SET_AUTHORISED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const GET_USER_DATA = createRequestTypes('GET_USER_DATA');
export const SEND_OTP = createRequestTypes('SEND_OTP');
export const VERIFY_OTP = createRequestTypes('VERIFY_OTP');
export const POST_DATA = createRequestTypes('POST_DATA');
export const GET_TRANSACTIONS = createRequestTypes('GET_TRANSACTIONS');
export const SET_OPENING_BAL = createRequestTypes('SET_OPENING_BAL');
export const SET_CATEGORY = createRequestTypes('SET_CATEGORY');

export const GET_LINKED_TRANSACTIONS = createRequestTypes(
  'GET_LINKED_TRANSACTIONS',
);
export const SET_LINKED_TRANSACTIONS = createRequestTypes(
  'SET_LINKED_TRANSACTIONS',
);

export const DELETE_TRANSACTION = createRequestTypes('DELETE_TRANSACTION');
export const GET_STATEMENTS = createRequestTypes('GET_STATEMENTS');
export const REFRESH_TOKEN = createRequestTypes('REFRESH_TOKEN');
export const GET_GRAPH_FILTER = createRequestTypes('GET_GRAPH_FILTER');
export const GET_CREDIT_REQUET_BY_CUSTOMER_NAME = createRequestTypes(
  'GET_CREDIT_REQUET_BY_CUSTOMER_NAME',
);
export const GET_DEBIT_REQUET_BY_CUSTOMER_NAME = createRequestTypes(
  'GET_DEBIT_REQUET_BY_CUSTOMER_NAME',
);
export const GET_TRANSACTION_BY_CUSTOMER_NAME = createRequestTypes(
  'GET_TRANSACTION_BY_CUSTOMER_NAME',
);
export const GET_CREDIT_TRANSACTIONS_BY_TYPE = createRequestTypes(
  'GET_CREDIT_TRANSACTIONS_BY_TYPE',
);
export const GET_DEBIT_TRANSACTIONS_BY_TYPE = createRequestTypes(
  'GET_DEBIT_TRANSACTIONS_BY_TYPE',
);
export const GET_TOTAL_TRANSACTIONS_BY_TYPE = createRequestTypes(
  'GET_TOTAL_TRANSACTIONS_BY_TYPE',
);
export const GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT = createRequestTypes(
  'GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT',
);
export const GET_TIME_SERIES_AMOUNT = createRequestTypes(
  'GET_TIME_SERIES_AMOUNT',
);
export const GET_TRANSACTION_DETAIL = createRequestTypes(
  'GET_TRANSACTION_DETAIL',
);
export const GET_NUMBER_OF_TRANSACTION = createRequestTypes(
  'GET_NUMBER_OF_TRANSACTION',
);

export const GET_CASH_FLOW = createRequestTypes('GET_CASH_FLOW');
export const GET_TRADE_RECEIVABLES = createRequestTypes(
  'GET_TRADE_RECEIVABLES',
);
export const GET_TRADE_PAYABLES = createRequestTypes('GET_TRADE_PAYABLES');
export const GET_REVENUE = createRequestTypes('GET_REVENUE');
export const GET_MATERIALS = createRequestTypes('GET_MATERIALS');
export const GET_CASHOUT = createRequestTypes('GET_CASHOUT');
export const GET_CASHIN = createRequestTypes('GET_CASHIN');
export const GET_PURCHASES = createRequestTypes('GET_PURCHASES');
export const GET_INVENTORY = createRequestTypes('GET_INVENTORY');
export const GET_CASHBALANCE = createRequestTypes('GET_CASHBALANCE');

export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const RESET_TRANSACTION = 'RESET_TRANSACTION';


export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_COUNTRY_ISO = 'SET_COUNTRY_ISO';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_GRAPH_FILTER = 'SET_GRAPH_FILTER';
export const SET_CREDIT_REQUET_BY_CUSTOMER_NAME =
  'SET_CREDIT_REQUET_BY_CUSTOMER_NAME';
export const SET_DEBIT_REQUET_BY_CUSTOMER_NAME =
  'SET_DEBIT_REQUET_BY_CUSTOMER_NAME';
export const SET_TRANSACTION_BY_CUSTOMER_NAME =
  'SET_TRANSACTION_BY_CUSTOMER_NAME';
export const SET_TOTAL_TRANSACTIONS_BY_TYPE = 'SET_TOTAL_TRANSACTIONS_BY_TYPE';
export const SET_CREDIT_TRANSACTIONS_BY_TYPE =
  'SET_CREDIT_TRANSACTIONS_BY_TYPE';
export const SET_DEBIT_TRANSACTIONS_BY_TYPE = 'SET_DEBIT_TRANSACTIONS_BY_TYPE';
export const SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT =
  'SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT';
export const SET_TIME_SERIES_AMOUNT = 'SET_TIME_SERIES_AMOUNT';
export const SET_NUMBER_OF_TRANSACTION = 'SET_NUMBER_OF_TRANSACTION';
export const SET_CASH_FLOW = 'SET_CASH_FLOW';
export const SET_TRADE_RECEIVABLES = 'SET_TRADE_RECEIVABLES';
export const SET_TRADE_PAYABLES = 'SET_TRADE_PAYABLES';
export const SET_REVENUE = 'SET_REVENUE';
export const SET_MATERIALS = 'SET_MATERIALS';
export const SET_CASHOUT = 'SET_CASHOUT';
export const SET_CASHIN = 'SET_CASHIN';
export const SET_PURCHASES = 'SET_PURCHASES';
export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_CASHBALANCE = 'SET_CASHBALANCE';
export const DELETE_LINKED_TRANSACTION = createRequestTypes(
  'DELETE_LINKED_TRANSACTION',
);
export const DELETE_LINKED_COLLECTION = createRequestTypes(
  'DELETE_LINKED_COLLECTION',
);
export const RENAME_LINK = createRequestTypes('RENAME_LINK');
export const GET_CASHBOX = createRequestTypes('GET_CASHBOX');
export const SET_CASHBOX = 'SET_CASHBOX';
export const GET_BOOKMARKED = createRequestTypes('GET_BOOKMARKED');
export const SET_BOOKMARKED = createRequestTypes('SET_BOOKMARKED');
export const DELETE_BOOKMARKED = createRequestTypes('DELETE_BOOKMARKED');
export const GET_LIABILITIES_REMINDERS = createRequestTypes('GET_LIABILITIES_REMINDERS');
export const GET_ASSETS_REMINDERS = createRequestTypes('GET_ASSETS_REMINDERS');
export const SHARE_REMINDERS = createRequestTypes('SHARE_REMINDERS');

