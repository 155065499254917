/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      // eslint-disable-next-line react/no-unused-state
      errorInfo: null,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error, info) {
    console.error('Error caught by ErrorBoundary:', error, info);
  }

  static getDerivedStateFromError(error) {
    console.log('\n getDerivedStateFromError error...', error, typeof error);
    return { hasError: true, errorInfo: error };
  }

  handleRedirect = () => {
    // Redirect to the home page
    console.log('\n click for go back....');
    this.props.history.push('/');
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (this.state.hasError) {
      return (
        <div>
          <p style={{ marginBottom: '10px' }}>
            <b>Something went wrong.</b>
          </p>
          <button onClick={this.handleRedirect}>Go to Home</button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
