import { take, put, call, fork } from 'redux-saga/effects';
import {
  GET_TRANSACTIONS,
  GET_LINKED_TRANSACTIONS,
  DELETE_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  GET_STATEMENTS,
  SET_OPENING_BAL,
  SET_CATEGORY,
  SET_LINKED_TRANSACTIONS,
  DELETE_LINKED_TRANSACTION,
  DELETE_LINKED_COLLECTION,
  RENAME_LINK,
  GET_BOOKMARKED,
  SET_BOOKMARKED,
  DELETE_BOOKMARKED,
  GET_REMINDERS,
  GET_ASSETS_REMINDERS,
  GET_LIABILITIES_REMINDERS,
} from '../actions/ActionTypes';
import { SAGA_ALERT_TIMEOUT, SOMETHING_WRONG } from '../constants';
import {
  getTransactionsSuccess,
  getLinkedTransactionsSuccess,
  getTransactionDetailsSuccess,
  deleteTransactionsSuccess,
  getStatementsSuccess,
  setOpeningBalRequestSuccess,
  setLinkedTransactionSuccess,
  deleteLinkedTransactionsSuccess,
  deleteLinkedCollectionSuccess,
  renameLinkSuccess,
  getBookmarkSuccess,
  setBookmarkSuccess,
  deleteBookmarkSuccess,
  getAssetsRemindersSuccess,
  getLiabilitiesRemindersRequest,
  getLiabilitiesRemindersSuccess
} from '../actions/TransactionsActions';

import {
  GET_TRANSACTIONS as GET_TRANSACTIONS_URL,
  SET_LINKED_TRANSACTIONS as SET_LINKED_TRANSACTIONS_URL,
  GET_LINKED_TRANSACTIONS as GET_LINKED_TRANSACTIONS_URL,
  DELETE_TRANSACTION as DELETE_TRANSACTION_URL,
  SET_OPENING_BAL as SET_OPENING_BAL_URL,
  GET_TRANSACTION_DETAIL as GET_TRANSACTION_DETAIL_URL,
  GET_STATEMENTS as GET_STATEMENTS_URL,
  SET_CATEGORY as SET_CATEGORY_URL,
  callRequest,
  DELETE_LINKED_TRANSACTION as DELETE_LINKED_TRANSACTION_URL,
  DELETE_LINKED_COLLECTION as DELETE_LINKED_COLLECTION_URL,
  RENAME_LINK as RENAME_LINK_URL,
  GET_BOOKMARK as GET_BOOKMARK_URL,
  SET_BOOKMARK as SET_BOOKMARK_URL,
  DELETE_BOOKMARK as DELETE_BOOKMARK_URL,
  GET_REMINDERS as GET_REMINDERS_URL,
  GET_ASSETS_REMINDERS as GET_ASSETS_REMINDERS_URL,
  GET_LIABILITIES_REMINDERS as GET_LIABILITIES_REMINDERS_URL,
} from '../config/WebService';
import ApiSauce from '../services/ApiSauce';

function alert(message, type = 'error') {
  setTimeout(() => {}, SAGA_ALERT_TIMEOUT);
}

function* getTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_TRANSACTIONS.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_TRANSACTIONS_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getTransactionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getLinkedTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(
      GET_LINKED_TRANSACTIONS.REQUEST,
    );

    try {
      const response = yield call(
        callRequest,
        GET_LINKED_TRANSACTIONS_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getLinkedTransactionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getTransactionDetails() {
  while (true) {
    const { payload, responseCallback } = yield take(
      GET_TRANSACTION_DETAIL.REQUEST,
    );

    try {
      const response = yield call(
        callRequest,
        GET_TRANSACTION_DETAIL_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getTransactionDetailsSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* deleteTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(
      DELETE_TRANSACTION.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        DELETE_TRANSACTION_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      if (response.length > 0) {
        yield put(deleteTransactionsSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}
function* setOpeningBal() {
  while (true) {
    const { payload, responseCallback } = yield take(SET_OPENING_BAL.REQUEST);
    try {
      const response = yield call(
        callRequest,
        SET_OPENING_BAL_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      if (response.length > 0) {
        yield put(setOpeningBalRequestSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* setCategory() {
  while (true) {
    const { payload, responseCallback } = yield take(SET_OPENING_BAL.REQUEST);
    try {
      const response = yield call(
        callRequest,
        SET_CATEGORY_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      // if (response.length > 0) {
      //   yield put(setOpeningBalRequestSuccess(payload));
      //   if (responseCallback) responseCallback(true);
      // } else {
      //   if (responseCallback) responseCallback(false);
      //   alert(response.message || SOMETHING_WRONG);
      // }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* setLinkedTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(
      SET_LINKED_TRANSACTIONS.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        SET_LINKED_TRANSACTIONS_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(setLinkedTransactionSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* getStatements() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_STATEMENTS.REQUEST);
    let GET_STATEMENTS_URL_NEW = {
      route: '/dashboard/reports/' + payload.urlType + '/',
      auth: true,
      type: 'post',
    };
    try {
      const response = yield call(
        callRequest,
        GET_STATEMENTS_URL_NEW,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response?.result) {
        yield put(getStatementsSuccess(response.result));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) {
        let message =
          err?.errors && err?.errors.length > 0
            ? err?.errors[0]
            : SOMETHING_WRONG;
        responseCallback(false, message);
      }
      alert(err.message);
    }
  }
}

function* deleteLinkedTransactions() {
  while (true) {
    const { payload, responseCallback } = yield take(
      DELETE_LINKED_TRANSACTION.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        DELETE_LINKED_TRANSACTION_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      if (response && response.ok == 1) {
        yield put(deleteLinkedTransactionsSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* deleteLinkedCollection() {
  while (true) {
    const { payload, responseCallback } = yield take(
      DELETE_LINKED_COLLECTION.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        DELETE_LINKED_COLLECTION_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response && response.ok == 1) {
        yield put(deleteLinkedCollectionSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* renameLinked() {
  while (true) {
    const { payload, responseCallback } = yield take(RENAME_LINK.REQUEST);
    try {
      const response = yield call(
        callRequest,
        RENAME_LINK_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(renameLinkSuccess(payload));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      console.log('\n renameLinked here is error', err.message || err);
      if (responseCallback)
        responseCallback(false, err?.errors[0] || SOMETHING_WRONG);
      alert(err.message);
    }
  }
}

function* getBookmarks() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_BOOKMARKED.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_BOOKMARK_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getBookmarkSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* setBookmark() {
  while (true) {
    const { payload } = yield take(SET_BOOKMARKED.REQUEST);
    try {
      const newUrlSection = {
        ...SET_BOOKMARK_URL,
        route: `${SET_BOOKMARK_URL.route}?transactionId=${payload?.transactionId}`,
      };
      const response = yield call(
        callRequest,
        newUrlSection,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(setBookmarkSuccess(response));
      } else {
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      alert(err.message);
    }
  }
}
function* deleteBookmark() {
  while (true) {
    const { payload } = yield take(DELETE_BOOKMARKED.REQUEST);
    try {
      const newUrlSection = {
        ...DELETE_BOOKMARK_URL,
        route: `${DELETE_BOOKMARK_URL.route}?transactionId=${payload?.transactionId}`,
      };
      const response = yield call(
        callRequest,
        newUrlSection,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        yield put(deleteBookmarkSuccess(payload));
      } else {
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      alert(err.message);
    }
  }
}
function* getAssetsReminders() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_ASSETS_REMINDERS.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_ASSETS_REMINDERS_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        console.log('+++++++++++++',response)
        yield put(getAssetsRemindersSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getLiabilitiesReminders() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_LIABILITIES_REMINDERS.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_LIABILITIES_REMINDERS_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        yield put(getLiabilitiesRemindersSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

export default function* root() {
  yield fork(getTransactions);
  yield fork(getLinkedTransactions);
  yield fork(getTransactionDetails);
  yield fork(deleteTransactions);
  yield fork(setOpeningBal);
  yield fork(getStatements);
  yield fork(setCategory);
  yield fork(setLinkedTransactions);
  yield fork(deleteLinkedTransactions);
  yield fork(deleteLinkedCollection);
  yield fork(renameLinked);
  yield fork(getBookmarks);
  yield fork(setBookmark);
  yield fork(deleteBookmark);
  yield fork(getAssetsReminders);
  yield fork(getLiabilitiesReminders);
}
