import { take, put, call, fork } from 'redux-saga/effects';
import {
  USER_UPLOAD_LOGO,
  GET_USER_DATA,
  UPDATE_USER,
} from '../actions/ActionTypes';
import { useDispatch } from 'react-redux';
import {
  SAGA_ALERT_TIMEOUT,
  SOMETHING_WRONG,
  MESSAGE_TYPES,
  SIGNUP_SUCCESS_MSG,
} from '../constants';
import {
  getUserDataSuccess,
  updateUserSuccess,
  updateUserRequest,
  setLoading,
} from '../actions/UserActions';

import {
  USER_UPLOAD_LOGO as USER_UPLOAD_LOGO_URL,
  GET_USER_DATA as GET_USER_DATA_URL,
  UPDATE_USER as UPDATE_USER_URL,
  callRequest,
} from '../config/WebService';
import ApiSauce from '../services/ApiSauce';
import { makeUSerData } from '../helpers/userHelper';
// const { loading: loading2 } = props.user;
function alert(message, type = 'error') {
  setTimeout(() => {}, SAGA_ALERT_TIMEOUT);
}

function* getUser() {
  while (true) {
    const { responseCallback } = yield take(GET_USER_DATA.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_USER_DATA_URL,
        {},
        '',
        {},
        ApiSauce,
      );

      console.log('\n\n\n\n\n user data response', response);
      if (!response) {
        // updateUserRequest({ loading: false });
        // yield put(setLoading({ loading: '3' }));
      }
      if (response.user) {
        yield put(getUserDataSuccess(makeUSerData(response)));
        if (responseCallback) responseCallback();
      } else {
        // yield put(setLoading({ loading: '3' }));
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      console.log('\n\n\n\n\n error in user data response', err.message || err);
      // yield put(setLoading({ loading: '3' }));
      // updateUserRequest({ loading: false });
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* updateUser() {
  while (true) {
    const { payload, responseCallback } = yield take(UPDATE_USER.REQUEST);

    try {
      const response = yield call(
        callRequest,
        UPDATE_USER_URL,
        payload,
        '',
        {},
        ApiSauce,
      );

      if (response) {
        yield put(updateUserSuccess(makeUSerData(response)));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

export default function* root() {
  yield fork(getUser);
  yield fork(updateUser);
}
