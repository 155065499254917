/* eslint-disable import/no-anonymous-default-export */
// @flow
import Immutable from 'seamless-immutable';
import {
  SET_GRAPH_FILTER,
  SET_CREDIT_REQUET_BY_CUSTOMER_NAME,
  SET_DEBIT_REQUET_BY_CUSTOMER_NAME,
  SET_TRANSACTION_BY_CUSTOMER_NAME,
  SET_TOTAL_TRANSACTIONS_BY_TYPE,
  SET_CREDIT_TRANSACTIONS_BY_TYPE,
  SET_DEBIT_TRANSACTIONS_BY_TYPE,
  SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT,
  SET_TIME_SERIES_AMOUNT,
  SET_NUMBER_OF_TRANSACTION,
  SET_CASH_FLOW,
  SET_TRADE_RECEIVABLES,
  SET_TRADE_PAYABLES,
  SET_REVENUE,
  SET_MATERIALS,
  SET_CASHOUT,
  SET_CASHIN,
  SET_PURCHASES,
  SET_INVENTORY,
  SET_CASHBALANCE,
  SET_CASHBOX,
} from '../actions/ActionTypes';

const initialState = Immutable({
  graphFilter: null,
  creditRequestByCustomerName: null,
  debitRequestByCustomerName: null,
  transactionsByCustomerName: null,
  totalTransactionByType: null,
  creditTransactionByType: null,
  debitTransactionByType: null,
  totalTransactionByMonthCreditDebit: null,
  totalTimeSeriesData: null,
  noofTransactions: null,
  setCashFlow: null,
  setTradeReceivables: null,
  setTradePayables: null,
  setRevenue: null,
  setMaterials: null,
  setCashout: null,
  setCashin: null,
  setPurchases: null,
  setInventory: null,
  setCashBalance: null,
  noofCashBalance:null,
  cashBoxData:[]

});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GRAPH_FILTER: {
      return Immutable.merge(state, {
        graphFilter: action.graphFilter,
      });
    }
    case SET_CREDIT_REQUET_BY_CUSTOMER_NAME: {
      return Immutable.merge(state, {
        creditRequestByCustomerName: action.creditRequestByCustomerName,
      });
    }

    case SET_DEBIT_REQUET_BY_CUSTOMER_NAME: {
      return Immutable.merge(state, {
        debitRequestByCustomerName: action.debitRequestByCustomerName,
      });
    }
    case SET_TIME_SERIES_AMOUNT: {
      return Immutable.merge(state, {
        totalTimeSeriesData: action.totalTimeSeriesData,
      });
    }
    case SET_NUMBER_OF_TRANSACTION: {
      return Immutable.merge(state, {
        noofTransactions: action.noofTransactions,
      });
    }
    case SET_CASH_FLOW: {
      return Immutable.merge(state, {
        noofCashFlow: action.noofCashFlow,
      });
    }
    case SET_TRADE_RECEIVABLES: {
      return Immutable.merge(state, {
        noofTradeReceivables: action.noofTradeReceivables,
      });
    }

    case SET_TRADE_PAYABLES: {
      return Immutable.merge(state, {
        noofTradePayables: action.noofTradePayables,
      });
    }
    case SET_REVENUE: {
      return Immutable.merge(state, {
        noofRevenue: action.noofRevenue,
      });
    }
    case SET_MATERIALS: {
      return Immutable.merge(state, {
        noofMaterials: action.noofMaterials,
      });
    }
    case SET_CASHOUT: {
      return Immutable.merge(state, {
        noofCashout: action.noofCashout,
      });
    }
    case SET_CASHIN: {
      return Immutable.merge(state, {
        noofCashin: action.noofCashin,
      });
    }
    case SET_PURCHASES: {
      return Immutable.merge(state, {
        noofPurchases: action.noofPurchases,
      });
    }
    case SET_INVENTORY: {
      return Immutable.merge(state, {
        noofInventory: action.noofInventory,
      });
    }

    case SET_CASHBALANCE: {
      return Immutable.merge(state, {
        noofCashBalance: action.noofCashBalance,
      });
    }

    case SET_TRANSACTION_BY_CUSTOMER_NAME: {
      return Immutable.merge(state, {
        transactionsByCustomerName: action.transactionsByCustomerName,
      });
    }

    case SET_TOTAL_TRANSACTIONS_BY_TYPE: {
      return Immutable.merge(state, {
        totalTransactionByType: action.totalTransactionByType,
      });
    }

    case SET_CREDIT_TRANSACTIONS_BY_TYPE: {
      return Immutable.merge(state, {
        creditTransactionByType: action.creditTransactionByType,
      });
    }

    case SET_DEBIT_TRANSACTIONS_BY_TYPE: {
      return Immutable.merge(state, {
        debitTransactionByType: action.debitTransactionByType,
      });
    }

    case SET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT: {
      return Immutable.merge(state, {
        totalTransactionByMonthCreditDebit:
          action.totalTransactionByMonthCreditDebit,
      });
    }

    case SET_CASHBOX: {
      return Immutable.merge(state, {
        cashBoxData: action.cashBoxData,
      });
    }

    default:
      return state;
  }
};
