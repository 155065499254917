import { take, put, call, fork } from 'redux-saga/effects';
import {
  USER_UPLOAD_LOGO,
  GET_USER_DATA,
  UPDATE_USER,
  GET_GRAPH_FILTER,
  GET_DEBIT_REQUET_BY_CUSTOMER_NAME,
  GET_CREDIT_REQUET_BY_CUSTOMER_NAME,
  GET_TRANSACTION_BY_CUSTOMER_NAME,
  GET_TOTAL_TRANSACTIONS_BY_TYPE,
  GET_CREDIT_TRANSACTIONS_BY_TYPE,
  GET_DEBIT_TRANSACTIONS_BY_TYPE,
  GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT,
  GET_TIME_SERIES_AMOUNT,
  GET_NUMBER_OF_TRANSACTION,
  GET_CASH_FLOW,
  GET_TRADE_RECEIVABLES,
  GET_TRADE_PAYABLES,
  GET_REVENUE,
  GET_MATERIALS,
  GET_CASHOUT,
  GET_CASHIN,
  GET_PURCHASES,
  GET_INVENTORY,
  GET_CASHBALANCE,
  GET_CASHBOX
} from '../actions/ActionTypes';
import { SAGA_ALERT_TIMEOUT, SOMETHING_WRONG } from '../constants';
import {
  setGraphFilter,
  setCreditRequestAmountByCustomerName,
  setDebitRequestAmountByCustomerName,
  setTransactionsByCustomerName,
  setTotalTransactionType,
  setCreditTransactionByType,
  setDebitTransactionByType,
  setTimeSeriesAmount,
  setTotalTransactionByMonthCreditDebit,
  setNoOfTransactions,
  setCashFlow,
  setTradeReceivables,
  setTradePayables,
  setRevenue,
  setMaterials,
  setCashout,
  setCashin,
  setPurchases,
  setInventory,
  setCashBalance,
  setCashBox
  // getCashFlow,
} from '../actions/GraphActions';

import {
  USER_UPLOAD_LOGO as USER_UPLOAD_LOGO_URL,
  GET_USER_DATA as GET_USER_DATA_URL,
  UPDATE_USER as UPDATE_USER_URL,
  callRequest,
  GET_GRAPH_FILTER_DATA,
  GET_CREDIT_REQUET_BY_CUSTOMER_NAME_DATA,
  GET_DEBIT_REQUET_BY_CUSTOMER_NAME_DATA,
  GET_TRANSACTION_BY_CUSTOMER_NAME_DATA,
  GET_TOTAL_TRANSACTIONS_BY_TYPE_DATA,
  GET_CREDIT_TRANSACTIONS_BY_TYPE_DATA,
  GET_DEBIT_TRANSACTIONS_BY_TYPE_DATA,
  GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT_DATA,
  GET_TIME_SERIES_AMOUNT_DATA,
  GET_NUMBER_OF_TRANSACTION_DATA,
  GET_CASH_FLOW_DATA,
  GET_TRADE_RECEIVABLES_DATA,
  GET_TRADE_PAYABLES_DATA,
  GET_REVENUE_DATA,
  GET_MATERIALS_DATA,
  GET_CASHOUT_DATA,
  GET_CASHIN_DATA,
  GET_PURCHASES_DATA,
  GET_INVENTORY_DATA,
  GET_CASHBALANCE_DATA,
  GET_CASHBOX_DATA
} from '../config/WebService';
import ApiSauce from '../services/ApiSauce';

function alert(message, type = 'error') {
  setTimeout(() => {}, SAGA_ALERT_TIMEOUT);
}

function* getInitialFilters() {
  while (true) {
    const { responseCallback } = yield take(GET_GRAPH_FILTER.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_GRAPH_FILTER_DATA,
        {},
        '',
        {},
        ApiSauce,
      );

      yield put(setGraphFilter(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCreditRequestAmountByCustomerName() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_CREDIT_REQUET_BY_CUSTOMER_NAME.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_CREDIT_REQUET_BY_CUSTOMER_NAME_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCreditRequestAmountByCustomerName(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getDebitRequestAmountByCustomerName() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_DEBIT_REQUET_BY_CUSTOMER_NAME.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_DEBIT_REQUET_BY_CUSTOMER_NAME_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setDebitRequestAmountByCustomerName(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getTransactionsByCustomerName() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TRANSACTION_BY_CUSTOMER_NAME.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TRANSACTION_BY_CUSTOMER_NAME_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setTransactionsByCustomerName(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getTotalTransactionByType() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TOTAL_TRANSACTIONS_BY_TYPE.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TOTAL_TRANSACTIONS_BY_TYPE_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setTotalTransactionType(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCreditTransactionByType() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_CREDIT_TRANSACTIONS_BY_TYPE.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_CREDIT_TRANSACTIONS_BY_TYPE_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCreditTransactionByType(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getdebitTransactionByType() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_DEBIT_TRANSACTIONS_BY_TYPE.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_DEBIT_TRANSACTIONS_BY_TYPE_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setDebitTransactionByType(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getTotalTransactionByMonthCreditDebit() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );
      yield put(setTotalTransactionByMonthCreditDebit(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getTimeSeriesAmount() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TIME_SERIES_AMOUNT.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TIME_SERIES_AMOUNT_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setTimeSeriesAmount(response?.result));
    } catch (err) {
      console.error('errrrors222', err);
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getNumberOfTransactions() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_NUMBER_OF_TRANSACTION.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_NUMBER_OF_TRANSACTION_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setNoOfTransactions(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getCashFlow() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_CASH_FLOW.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_CASH_FLOW_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCashFlow(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getTradeReceivables() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TRADE_RECEIVABLES.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TRADE_RECEIVABLES_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setTradeReceivables(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getTradePayables() {
  while (true) {
    const { responseCallback, payload } = yield take(
      GET_TRADE_PAYABLES.REQUEST,
    );
    try {
      const response = yield call(
        callRequest,
        GET_TRADE_PAYABLES_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setTradePayables(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getRevenue() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_REVENUE.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_REVENUE_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setRevenue(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getMaterials() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_MATERIALS.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_MATERIALS_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setMaterials(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCashout() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_CASHOUT.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_CASHOUT_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCashout(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCashin() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_CASHIN.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_CASHIN_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCashin(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}


function* getPurchases() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_PURCHASES.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_PURCHASES_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setPurchases(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getInventory() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_INVENTORY.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_INVENTORY_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setInventory(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCashBalance() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_CASHBALANCE.REQUEST);
    try {
      const response = yield call(
        callRequest,
        GET_CASHBALANCE_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCashBalance(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

function* getCashBox() {
  while (true) {
    const { responseCallback, payload } = yield take(GET_CASHBOX.REQUEST);
    try {
      console.log('\n\n\n getCashBox payload....', payload)
      const response = yield call(
        callRequest,
        GET_CASHBOX_DATA,
        payload,
        '',
        {},
        ApiSauce,
      );

      yield put(setCashBox(response?.result));
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}

export default function* root() {
  yield fork(getInitialFilters);
  yield fork(getCreditRequestAmountByCustomerName);
  yield fork(getDebitRequestAmountByCustomerName);
  yield fork(getTransactionsByCustomerName);
  yield fork(getTotalTransactionByType);
  yield fork(getCreditTransactionByType);
  yield fork(getdebitTransactionByType);
  yield fork(getTotalTransactionByMonthCreditDebit);
  yield fork(getTimeSeriesAmount);
  yield fork(getNumberOfTransactions);
  yield fork(getCashFlow);
  yield fork(getTradeReceivables);
  yield fork(getTradePayables);
  yield fork(getRevenue);
  yield fork(getMaterials);
  yield fork(getCashout);
  yield fork(getCashin);
  yield fork(getPurchases);
  yield fork(getInventory);
  yield fork(getCashBalance);
  yield fork(getCashBox);
}
