import { DEV_ENV } from '../constants';
import { getServer } from '../services/Util';
// import Util from "../services/Util";
import Util from '../services/Util';

import _ from 'lodash';
import DataHandler from '../services/DataHandler';
export const BASE_URL = getServer();

// export const BASE_URL = 'https://dev.access-loan.com/api/v1';

// export const BASE_URL = "https://api.kiffgo.com/";

// const MAP_KEY = "";

export const MAP_KEY = '';

export const G_API_URL = ``;
export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = 'Network failed. Aborted request.';

// API USER ROUTES
// export const API_LOG = process.env.REACT_APP_ENV === DEV_ENV;
export const API_LOG = false;

export const ERROR_SOMETHING_WENT_WRONG = {
  message: 'Something went wrong, Please try again later',
};
export const ERROR_NETWORK_NOT_AVAILABLE = {
  message: 'Please connect to working internet',
};

export const ERROR_TIMEOUT = {
  message: 'Request timeout, please check you internet!',
};

export const ERROR_SESSION_EXPIRED = {
  message: 'Session expired, please refresh',
};

export const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put',
  PATCH: 'PATCH',
};

// API USER ROUTES

export const GET_USER_DATA = {
  route: '/user',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const GET_GRAPH_FILTER_DATA = {
  route: '/dashboard/initial/filters/',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const GET_CREDIT_REQUET_BY_CUSTOMER_NAME_DATA = {
  route: '/dashboard/graphs/creditamountbycustomer/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_DEBIT_REQUET_BY_CUSTOMER_NAME_DATA = {
  route: '/dashboard/graphs/debitamountbycustomer/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TRANSACTION_BY_CUSTOMER_NAME_DATA = {
  route: '/dashboard/graphs/transactionsbycustomer/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TOTAL_TRANSACTIONS_BY_TYPE_DATA = {
  route: '/dashboard/graphs/totaltransactionsbytype/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_CREDIT_TRANSACTIONS_BY_TYPE_DATA = {
  route: '/dashboard/graphs/transactionsbycreditaccounttype/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_DEBIT_TRANSACTIONS_BY_TYPE_DATA = {
  route: '/dashboard/graphs/transactionsbydebitaccounttype/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TOTAL_TRANSACTIONS_BY_MONTH_CREDIT_DEBIT_DATA = {
  route: '/dashboard/graphs/changeoftransactions/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TIME_SERIES_AMOUNT_DATA = {
  route: '/dashboard/graphs/timeseries/amounts/',

  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_NUMBER_OF_TRANSACTION_DATA = {
  route: '/dashboard/graphs/timeseries/numberoftransaction/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_CASH_FLOW_DATA = {
  route: '/dashboard/graphs/cashflow/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_TRADE_RECEIVABLES_DATA = {
  route: '/dashboard/graphs/tradereceivables/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_TRADE_PAYABLES_DATA = {
  route: '/dashboard/graphs/tradepayables/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_REVENUE_DATA = {
  route: '/dashboard/graphs/revenue/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_MATERIALS_DATA = {
  route: '/dashboard/graphs/materials/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CASHOUT_DATA = {
  route: '/dashboard/graphs/cashout/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CASHIN_DATA = {
  route: '/dashboard/graphs/cashin/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_PURCHASES_DATA = {
  route: '/dashboard/graphs/purchases/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_INVENTORY_DATA = {
  route: '/dashboard/graphs/inventory/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_CASHBALANCE_DATA = {
  route: '/dashboard/reports/cashbalance/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_TRANSACTIONS = {
  route: '/transaction/unformatted',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const GET_NEXT_NODE = {
  route: '/chain/node',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const UPDATE_USER = {
  route: '/user',
  auth: true,
  type: REQUEST_TYPE.PUT,
};
export const POST_DATA = {
  route: '/transaction/save-result-from-array',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const DELETE_TRANSACTION = {
  route: '/transaction/delete',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};

export const SET_OPENING_BAL = {
  route: '/dashboard/reports/openingbalance/update/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const SET_CATEGORY = {
  route: '/dashboard/reports/openingbalance/update/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_STATEMENTS = {
  route: '/dashboard/reports/',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_TRANSACTION_DETAIL = {
  route: '/transaction/find-multiple',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const USER_UPLOAD_LOGO = {
  route: 'b/user/logo-upload',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const USER_SIGNIN = {
  route: 'b/login-website',
  auth: false,
  type: REQUEST_TYPE.POST,
};
export const LOGOUT_USER = {
  route: 'b/login-website',
  auth: true,
  type: REQUEST_TYPE.GET,
};
export const DELETE_SUPPLIER_NAME = {
  route: '/lookup',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};

export const GET_LINKED_TRANSACTIONS = {
  route: '/linked',
  auth: true,
  type: REQUEST_TYPE.GET,
};

export const SET_LINKED_TRANSACTIONS = {
  route: '/linked',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_MOST_TRANSACTIONS = {
  route: '/chain/repeat/transaction',
  auth: true,
  type: REQUEST_TYPE.GET,
};

export const DELETE_LINKED_TRANSACTION = {
  route: '/linked/transactions',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};

export const DELETE_LINKED_COLLECTION = {
  route: '/linked/links',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};
export const RENAME_LINK = {
  route: '/linked/rename',
  auth: true,
  type: REQUEST_TYPE.PATCH,
};

export const GET_CASHBOX_DATA = {
  route: '/dashboard/reports/cashbox/',
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const GET_BOOKMARK = {
  route: '/bookmarks',
  auth: true,
  type: REQUEST_TYPE.GET,
};

export const SET_BOOKMARK = {
  route: '/bookmarks',
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const DELETE_BOOKMARK = {
  route: '/bookmarks',
  auth: true,
  type: REQUEST_TYPE.DELETE,
};
export const GET_LIABILITIES_REMINDERS = {
  route: '/transaction/get-liabilities' ,
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const GET_ASSETS_REMINDERS = {
  route: '/transaction/get-assets' ,
  auth: true,
  type: REQUEST_TYPE.POST,
};
export const SHARE_REMINDERS = {
  route: '/transaction/share-reminder' ,
  auth: true,
  type: REQUEST_TYPE.POST,
};

export const callRequest = function (
  url,
  data,
  parameter,
  header = {},
  ApiSauce,
  baseUrl = BASE_URL,
) {
  const _url =
    parameter && !_.isEmpty(parameter)
      ? `${url.route}/${parameter}`
      : url.route;
  if (url.auth) {
    DataHandler.getStore();
    header.Authorization = `Bearer ${Util.getCurrentUserAccessToken()}`;
    // header.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InByb2ZpbGUiOnsibmFtZSI6IkhhcndpbmRlciBEZXZlbG9wZXIiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FDTlBFdV9hNmhkMTd6WUF5ZXhNTmJhR3NGV1BVMmItcFl3R2R3RlBjb1BRPXM5Ni1jIiwid29yayI6IndvcmsiLCJsb2NhdGlvbiI6ImNpdHkiLCJ0b2EiOnRydWV9LCJ0b2tlbnMiOltdLCJfaWQiOiI2MzM1NDQ2NzBhNWY3NjAwMTE2MTJkNDYiLCJlbWFpbCI6ImRldmhhcndpbmRlcjYxQGdtYWlsLmNvbSIsImxvY2FsZSI6ImVzX0FSIiwiY3JlYXRlZEF0IjoiMjAyMi0wOS0yOVQwNzowODoyMy4wNzJaIiwidXBkYXRlZEF0IjoiMjAyMi0xMC0xMlQwNjowMToyNC4xNDJaIiwiX192IjowLCJjb3VudHJ5IjoiQXJnZW50aW5hIiwiY291bnRyeUNvZGUiOiIrNTQiLCJjb3VudHJ5SVNPIjoiQVJHIiwiY3VycmVuY3kiOiJBUlMiLCJudW1iZXIiOjc2Nn0sImlhdCI6MTY3MTg4MjcyNCwiZXhwIjoxNjcxOTY5MTI0fQ.9hUjDfr0g3QPvKeU0bhXuxuSjlMUeCVGUczxYHXrgiE`;
  }
  if (url.type === REQUEST_TYPE.POST) {
    //data._csrf = Util.getCurrentCsrfToken();
    return ApiSauce.post(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.GET) {
    return ApiSauce.get(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.PUT) {
    // data._csrf = Util.getCurrentCsrfToken();
    return ApiSauce.put(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.DELETE) {
    return ApiSauce.delete(_url, data, header, baseUrl);
  } else if (url.type === REQUEST_TYPE.PATCH) {
    //data._csrf = Util.getCurrentCsrfToken();
    return ApiSauce.patch(_url, data, header, baseUrl);
  }
  // return ApiSauce.post(url.route, data, _header);
};
