import { take, put, call, fork } from 'redux-saga/effects';
import {
  GET_NEXT_NODE,
  POST_DATA,
  GET_SHOW_MORE_NEXT_NODE,
  DELETE_SUPPLIER,
} from '../actions/ActionTypes';
import { SAGA_ALERT_TIMEOUT, SOMETHING_WRONG } from '../constants';
import { getNextNodeSuccess } from '../actions/NodeActions';

import {
  GET_NEXT_NODE as GET_NEXT_NODE_URL,
  POST_DATA as POST_DATA_URL,
  callRequest,
  DELETE_SUPPLIER_NAME,
} from '../config/WebService';
import ApiSauce from '../services/ApiSauce';

function alert(message, type = 'error') {
  setTimeout(() => {}, SAGA_ALERT_TIMEOUT);
}

function* getNextNode() {
  while (true) {
    const { payload, responseCallback } = yield take(GET_NEXT_NODE.REQUEST);

    try {
      const response = yield call(
        callRequest,
        GET_NEXT_NODE_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        if (responseCallback) responseCallback(response);

        yield put(getNextNodeSuccess(response));
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* getShowMoreNextNode() {
  while (true) {
    const { payload, responseCallback } = yield take(
      GET_SHOW_MORE_NEXT_NODE.REQUEST,
    );

    try {
      const response = yield call(
        callRequest,
        GET_NEXT_NODE_URL,
        {},
        payload,
        {},
        ApiSauce,
      );
      if (response) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(null, 'err');
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(null, err);
      alert(err.message);
    }
  }
}
function* postArray() {
  while (true) {
    const { payload, responseCallback } = yield take(POST_DATA.REQUEST);
    try {
      const response = yield call(
        callRequest,
        POST_DATA_URL,
        payload,
        '',
        {},
        ApiSauce,
      );
      if (response) {
        // yield put(getNextNodeSuccess(response));
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        alert(response.message || SOMETHING_WRONG);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false);
      alert(err.message);
    }
  }
}

function* deleteSupplierName() {
  while (true) {
    const { payload, responseCallback } = yield take(DELETE_SUPPLIER);
    try {
      const response = yield call(
        callRequest,
        DELETE_SUPPLIER_NAME,
        {},
        payload,
        {},
        ApiSauce,
      );

    } catch (error) {
      console.error(error);
    }
  }
}

export default function* root() {
  yield fork(getNextNode);
  yield fork(postArray);
  yield fork(getShowMoreNextNode);
  yield fork(deleteSupplierName);
}
