import React, { Suspense, lazy } from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { CircularProgress, Box } from '@mui/material';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';
import theme from '../materialTheme';
import ErrorBoundary from '../ErrorBoundary';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';

const Home = lazy(() => import('../pages/home'));
const Graph = lazy(() => import('../pages/graph'));
const AddTransaction = lazy(() => import('../pages/addTransaction'));
const Register = lazy(() => import('../pages/register'));
const Transaction = lazy(() => import('../pages/transaction'));
const Reminder = lazy(() => import('../pages/reminder'));
const search = window.location.search;
const params = new URLSearchParams(search);
const tkn = params.get('tkn') || null;

const Loading = () => {
  // return <div className="loading">Loading...</div>;
  return (
    <div className={'loaderMainDiv  mainLoaderDiv'}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

class Routing extends React.PureComponent {
  render() {
    //@ts-ignore
    const { user } = this.props;
    const repeatedProps = {
      user,
      exact: true,
      forAdminOnly: false,
    };
    return (
      <ErrorBoundary>
        <MuiPickersUtilsProvider utils={DayJSUtils}>
          <ThemeProvider theme={theme}>
            <Router>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/register" />
                  </Route>
                  <Route path="/home" component={Home} />
                  <Route path="/graph" component={Graph} />

                  <Route path="/register" component={Register} />
                  <Route path="/add" component={AddTransaction} />
                  <Route path="/transaction/:id" component={Transaction} />
                  <Route path="/transactions/view" component={Reminder} />
                  <Route component={() => <Redirect to="/home" />} />
                </Switch>
              </Suspense>

            </Router>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    );
  }
}
//@ts-ignore
const mapStateToProps = ({ user }) => ({
  user,
});

const actions = {};

export default connect(mapStateToProps, actions)(Routing);
